import { useState, useEffect } from "react"; // useRef was being imported here for something on the old page. Removed it for now
import axios from "axios";
import "./Speedruns.css";
import { h3L } from "../api.js";
import { default as sandbox } from "../WebsitePics/sandbox.png";
import { default as crowne } from "../WebsitePics/crowne.png";
import { default as bellscolumnsv2 } from "../JumpMapPics/bellscolumnsv2.PNG";
import { default as chatoyant } from "../JumpMapPics/chatoyant.PNG";
import { default as clickitty } from "../JumpMapPics/clickitty.PNG";
import { default as dvnpathfinder } from "../JumpMapPics/dvnpathfinder.PNG";
import { default as ghdholyghost } from "../JumpMapPics/ghdholyghost.PNG";
import { default as impawsible } from "../JumpMapPics/impawsible.PNG";
import { default as jgdchallenge } from "../JumpMapPics/jgdchallenge.PNG";
import { default as jgdcoaster } from "../JumpMapPics/jgdcoaster.PNG";
import { default as jgdhouseofjumps } from "../JumpMapPics/jgdhouseofjumps.PNG";
import { default as jgdnerfed } from "../JumpMapPics/jgdnerfed.PNG";
import { default as jgdtechnical } from "../JumpMapPics/jgdtechnical.PNG";
import { default as jhpowertower } from "../JumpMapPics/jhpowertower.PNG";
import { default as jhskyspire } from "../JumpMapPics/jhskyspire.PNG";
import { default as jnogbang } from "../JumpMapPics/jnogbang.PNG";
import { default as jnogtrials } from "../JumpMapPics/jnogtrials.PNG";
import { default as jtgauntlet } from "../JumpMapPics/jtgauntlet.PNG";
import { default as kittymew } from "../JumpMapPics/kittymew.PNG";
import { default as kittyreset } from "../JumpMapPics/kittyreset.PNG";
import { default as lfbanjo } from "../JumpMapPics/lfbanjo.PNG";
import { default as lfchinacat } from "../JumpMapPics/lfchinacat.PNG";
import { default as lffutz } from "../JumpMapPics/lffutz.PNG";
import { default as lfysicynips } from "../JumpMapPics/lfysicynips.PNG";
import { default as pabigfoot } from "../JumpMapPics/pabigfoot.PNG";
import { default as pamango } from "../JumpMapPics/pamango.PNG";
import { default as parubble } from "../JumpMapPics/parubble.PNG";
import { default as pawakawaka } from "../JumpMapPics/pawakawaka.PNG";
import { default as soil } from "../JumpMapPics/soil.PNG";
import { default as srsinner } from "../JumpMapPics/srsinner.PNG";
import { default as syblam } from "../JumpMapPics/syblam.PNG";
import { default as wtkgabagool } from "../JumpMapPics/wtkgabagool.PNG";
import { default as wtkgambit } from "../JumpMapPics/wtkgambit.PNG";

const useMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

async function fetchData(apiUrl) {
    try {
        const response = await axios.get(apiUrl);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export default function Speedruns() {
    const [record, setRecord] = useState(null);
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedMap, setSelectedMap] = useState('');
    const [activeMap, setActiveMap] = useState(null);
    const apiUrlHalo3 = h3L;
    const isMobile = useMobile();

    useEffect(() => {
        if (isMobile) {
            import('./Speedruns_Mobile.css'); 
        }
    }, [isMobile]);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            const apiData = await fetchData(apiUrlHalo3);
            apiData[0].maps.sort((a, b) => a.name.localeCompare(b.name));
            setData(apiData);
            setRecord(null);
        };

        fetchDataFromApi();
    }, [apiUrlHalo3]);

    function setNewRecord(mapRecord) {
        setActiveMap(mapRecord);
        let img = sandbox;
        const mapBaseName = mapRecord.map_base_name.toLowerCase();
        const mapImages = {
            bellscolumnsv2,
            chatoyant,
            clickitty,
            dvnpathfinder,
            ghdholyghost,
            impawsible,
            jgdchallenge,
            jgdcoaster,
            jgdhouseofjumps,
            jgdnerfed,
            jgdtechnical,
            jhpowertower,
            jhskyspire,
            jnogbang,
            jnogtrials,
            jtgauntlet,
            kittymew,
            kittyreset,
            lfbanjo,
            lfchinacat,
            lffutz,
            lfysicynips,
            pabigfoot,
            pamango,
            parubble,
            pawakawaka,
            soil,
            srsinner,
            syblam,
            wtkgabagool,
            wtkgambit,
        };

        if (mapImages[mapBaseName]) {
            img = mapImages[mapBaseName];
        }

        let runs = mapRecord.wr_holders.sort((a, b) => {
            const timeA = a.record_time.split(":").reduce((acc, time, index) => acc + parseInt(time) * [3600, 60, 1][index], 0);
            const timeB = b.record_time.split(":").reduce((acc, time, index) => acc + parseInt(time) * [3600, 60, 1][index], 0);
            return timeA - timeB;
        });

        setRecord([{
            mapImg: img,
            mapName: mapRecord.name,
            mapCreator: mapRecord.creator,
            speedruns: runs
        }]);
        if (isMobile) {
            setVisible(false);
        }
    }

    function handleMapChange(event) {
        setSelectedMap(event.target.value);
    }

    useEffect(() => {
        const handleNavbarOpened = () => {
          if (isMobile) {
            setVisible(false); // Close the sidebar when the navbar is opened
          }
        };
      
        window.addEventListener('navbarOpened', handleNavbarOpened);
      
        return () => {
          window.removeEventListener('navbarOpened', handleNavbarOpened);
        };
      }, [isMobile]);
      
      const toggleSidebar = () => {
        setVisible(!visible);
        if (isMobile) {
          // Emit an event to close the navbar
          window.dispatchEvent(new Event('sidebarOpened'));
        }
      };

    return (
        <div className="speedruns-page-container">
            <div className={`speedruns-right-sidebar ${visible ? "visible" : "hidden"}`}>
                <div className="speedruns-sidebar-header">
                    <button className="speedruns-game-switch-button active" onClick={() => {setRecord(null); if (isMobile) {setVisible(false)}}}>Speedrunning Rules</button>
                </div>
                <ul className="speedruns-map-list">
                    {data[0]?.maps.map((map, index) => (
                        <li
                            key={index}
                            className={`speedruns-map-item ${activeMap === map ? "active" : ""}`}
                            onClick={() => setNewRecord(map)}
                        >
                            {map.name}
                        </li>
                    ))}
                </ul>
            </div>

            <button
            className={`speedruns-sidebar-toggle-button ${visible ? "visible" : "hidden"}`}
            onClick={toggleSidebar}
            >
            {visible ? "❯" : "❮"}
            </button>

            <div className={`speedruns-content-container ${visible ? "speedruns-sidebar-visible" : "speedruns-sidebar-hidden"}`}>
                {record ? (
                    <div className="speedruns-record-details">
                        <img className="speedruns-map-image" src={record[0].mapImg} alt="Map"></img>
                        <div className="speedruns-map-details">
                            <div className="speedruns-map-details-item">
                                <strong>Map Name:</strong> <span>{record[0].mapName}</span>
                            </div>
                            <div className="speedruns-map-details-item">
                                <strong>Author:</strong> <span>{record[0].mapCreator}</span>
                            </div>
                            <div className="speedruns-map-details-item">
                                <strong>Map Description:</strong> <span>N/A</span>
                            </div>
                            <div className="speedruns-map-details-item">
                                <strong>Creation Date:</strong> <span>N/A</span>
                            </div>
                            <div className="speedruns-map-details-item">
                                <strong>Recommended game mode:</strong> <span>N/A</span>
                            </div>
                            <div className="speedruns-button-container">
                                <button className="speedruns-center-button">Download {record[0].mapName}</button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="speedruns-rules-container">
                        <h1>Halo 3 Speedrunning Rules</h1>
                        <div className="speedruns-rules-section">
                            <h2>~ General Rules ~</h2>
                            <p>All submitted runs must be in 1st Person, should not be ran on modified versions of the map or game unless for the purpose of resetting such as in the case of Save States. Every run must start with the map in its default state, no physical alterations should be made such as moving objects or affecting the rng of certain objects such as fusion coils or bouncing golf/soccer balls.</p>
                        </div>
                        <div className="speedruns-rules-section">
                            <h2>~ Game Mode Rules ~</h2>
                            <p>Unless the map specifies the need for a certain game type, only the modes with standard jump settings: Oddball, no alterations to speed, jump height, etc. are valid. Third party assistance such as Butterfly, double jumping, or any player interaction one might see in CGB is disallowed. Weapons will not be forced unless the map requires you to have those weapons e.g. grenades, rockets, and bruteshots.</p>
                        </div>
                        <div className="speedruns-rules-section">
                            <h2>~ HTJ Leaderboards ~</h2>
                            <p>We are aware that HTJ has their own speedrunning leaderboard and some of you may have submitted runs to theirs. We are keeping our leaderboard separate from theirs and will not be using the same maps they currently use. We want to keep ours separate to keep things fresh and use different maps that now have current records.</p>
                        </div>
                        <div className="speedruns-rules-section">
                            <h2>~ Timer ~</h2>
                            <p>The timer begins on the frame the player makes a movement and ends on the frame the Oddball is picked up. Use this tool to help time your runs correctly. If you are having issues let Pownin know and we will figure it out.</p>
                        </div>
                        <div className="speedruns-map-specific-section">
                            <h2>~ Map Specific Rules ~</h2>
                            <select onChange={handleMapChange} value={selectedMap} className="speedruns-map-dropdown">
                                <option value="">Select a Map</option>
                                <option value="jgdcoaster">JGD Coaster</option>
                                <option value="jtgauntlet">JT Gauntlet</option>
                                <option value="wtkgambit">WTK Gambit</option>
                            </select>
                            {selectedMap && (
                                <div className="speedruns-map-specific-rules">
                                    {selectedMap === 'jgdcoaster' && (
                                        <p>There is a big skip in the start of the map. Runs that use this skip will not be valid and your run will not be counted toward the leaderboards. If you use this skip we will let you know that the run is invalid.</p>
                                    )}
                                    {selectedMap === 'jtgauntlet' && (
                                        <p>Runs that use getting on top of the map skips will not be valid. If you use these skips we will let you know your run is invalid.</p>
                                    )}
                                    {selectedMap === 'wtkgambit' && (
                                        <p>There is a huge skip at the start of the map. Runs that use this skip will not be counted toward the leaderboards. If you use this skip we will let you know that the run is invalid.</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {record && (
                    <div className="speedruns-details-text">
                    <h2>{record[0].mapName}</h2>
                    <p>Creator: {record[0].mapCreator}</p>
                    <h3>Speedruns</h3>
                    {record[0].speedruns.map((run, index) => (
                        <div key={index} className="speedruns-speedrun-entry">
                            {/* Only display the crown image for the first speedrun entry */}
                            {index === 0 && <img className="speedruns-crowne-image" src={crowne} alt="Crowne" />}
                            <p><strong>{index + 1}. {run.wr_holder}</strong> - {run.record_time}</p>
                            {run.video_url && (
                                <a href={`https://${run.video_url.replace(/^https?:\//, '')}`} target="_blank" rel="noopener noreferrer">
                                    Watch Run
                                </a>
                            )}
                        </div>
                    ))}
                </div>
                
                )}
            </div>
        </div>
    );
}
