import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import "./Register.css";

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 600);
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const RegisterLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [isLoginMode, setIsLoginMode] = useState(true);
  const navigate = useNavigate();
  const isMobile = useMobile();

    useEffect(() => {
        if (isMobile) {
            import('./Register_Mobile.css'); 
        }
    }, [isMobile]);

  const backendUrl = "https://servers.jumpnexus.org"; // Replace with your backend URL

  const handleRegister = async () => {
    try {
      setError("");

      if (!username.trim()) {
        setError("Username is required.");
        return;
      }

      const normalizedUsername = username.trim().toLowerCase();

      // Check username availability via backend
      const checkResponse = await fetch(`${backendUrl}/check-username?username=${normalizedUsername}`);
      const checkData = await checkResponse.json();

      if (!checkResponse.ok) {
        setError(checkData.message || "Error checking username.");
        return;
      }

      // Create user in Firebase Auth
      await createUserWithEmailAndPassword(auth, email, password);
      const token = await auth.currentUser.getIdToken();

      // Save user data via backend
      const saveResponse = await fetch(`${backendUrl}/save-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          username,
          email,
        }),
      });

      if (!saveResponse.ok) {
        const saveData = await saveResponse.json();
        setError(saveData.error || "Error saving user data.");
        return;
      }

      setEmail("");
      setPassword("");
      setUsername("");
      navigate("/");
    } catch (error) {
      setError("Registration failed. Please try again.");
    }
  };

  const handleLogin = async () => {
    try {
      setError("");

      if (!username.trim() || !password.trim()) {
        setError("Username and password are required.");
        return;
      }

      const normalizedUsername = username.trim().toLowerCase();

      // Fetch email associated with the username from the backend
      const emailResponse = await fetch(`${backendUrl}/get-email?username=${normalizedUsername}`);
      const emailData = await emailResponse.json();

      if (!emailResponse.ok || !emailData.email) {
        setError("Invalid username or password.");
        return;
      }

      // Use the retrieved email to log in with Firebase Auth
      await signInWithEmailAndPassword(auth, emailData.email, password);

      navigate("/");
    } catch (error) {
      setError("Login failed. Please check your username and password.");
    }
  };

  return (
    <div className="register-form-container">
      <h1>{isLoginMode ? "Login" : "Register"}</h1>
      <form
        className="register-form-box"
        onSubmit={(e) => {
          e.preventDefault(); // Prevent default form submission
          if (isLoginMode) {
            handleLogin();
          } else {
            handleRegister();
          }
        }}
      >
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
        />
        {!isLoginMode && (
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        )}
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <div className="register-button-group">
          <button
            type="submit" // Submit button triggers form submission
            className="register-button styled-button"
          >
            {isLoginMode ? "Login" : "Register"}
          </button>
        </div>
        {error && <p className="register-error-message">{error}</p>}
        <p>
          {isLoginMode ? "Don't have an account?" : "Already have an account?"}{" "}
          <span
            className="toggle-mode"
            onClick={() => setIsLoginMode(!isLoginMode)}
          >
            {isLoginMode ? "Register" : "Login"}
          </span>
        </p>
      </form>
    </div>
  );
};

export default RegisterLogin;