import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Sidebar, Menu, Segment, Icon, Dropdown, Image } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import '../navbar.css';
import { default as Home } from './pages/Home';
import { default as Speedruns } from './pages/Speedruns';
import { default as Logo } from './WebsitePics/NexusLogoRedesign.png';
import { default as DiscordServers } from './pages/DiscordServers';
import { default as CGB } from './pages/CGB';
import { default as Register } from './pages/Register';
import { default as UserProfile } from './pages/userprofile';
import { default as Tickets } from './pages/Tickets';
import { default as UserProfileView } from './UserProfileView';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import axios from 'axios';

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

// Configure Axios
axios.defaults.baseURL = 'https://servers.jumpnexus.org';

export default function MainNav({ children }) {
  const [visible, setVisible] = useState(true); // Sidebar visibility state
  const [isLoggedIn, setIsLoggedIn] = useState(false); // User login state
  const [username, setUsername] = useState(null); // User's username
  const [profilePicUrl, setProfilePicUrl] = useState(null); // User's profile picture
  const [loading, setLoading] = useState(true); // Loading state for user data
  const isMobile = useMobile();

  useEffect(() => {
    if (isMobile) {
      import('../navbar_mobile.css');
      setVisible(false); // Sidebar visibility state
    }
  }, [isMobile]);

  const handleButtonClick = () => {
    if (isMobile) {
      setVisible(false);
    }
  };

  // Fetch profile picture from the backend
  const fetchProfilePicture = async (uid) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get('/userdata', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = response.data[uid];
      if (userData) {
        setProfilePicUrl(userData.profilePicture || null); // Set profile picture
      } else {
        console.error('User data not found on the server.');
      }
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  // Monitor auth state and fetch username and profile picture
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        setIsLoggedIn(true);

        // Fetch the username from Firestore or another Firebase service
        try {
          const token = await user.getIdToken();
          const response = await axios.get(`/userdata/${user.uid}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          // Set the username from the response, fallback to 'User' if not found
          setUsername(response.data.username || 'User');
        } catch (error) {
          console.error('Error fetching username:', error);
          setUsername('User'); // Fallback username
        }

        // Fetch the profile picture from the backend
        await fetchProfilePicture(user.uid);
      } else {
        setIsLoggedIn(false);
        setUsername(null);
        setProfilePicUrl(null);
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  // Logout handler
  const handleLogout = async () => {
    const confirmation = window.confirm('Are you sure you want to log out?'); // Confirmation prompt
    if (confirmation) {
      try {
        await signOut(auth); // Sign out the user
        alert('You have been logged out.'); // Optional: Inform the user
        window.location.href = '/'; // Redirect to the home page
      } catch (error) {
        console.error('Error logging out:', error);
      }
    }
  };

  const handleToggleNavbar = () => {
    setVisible(!visible);
    if (!visible && isMobile) {
      // Emit an event to close sidebars on mobile
      window.dispatchEvent(new Event('navbarOpened'));
    }
  };

  useEffect(() => {
    const handleSidebarOpened = () => {
      if (isMobile) {
        setVisible(false); // Close the navbar when a sidebar is opened
      }
    };

    window.addEventListener('sidebarOpened', handleSidebarOpened);

    return () => {
      window.removeEventListener('sidebarOpened', handleSidebarOpened);
    };
  }, [isMobile]);

  return (
    <Router>
      <div className="navbar-container">
        {/* Sidebar */}
        <Sidebar
          as={Menu}
          animation="push"
          icon="labeled"
          inverted
          vertical
          visible={visible}
          width="thin"
          className={`navbar-sidebar-menu ${!visible ? 'navbar-sidebar-hidden' : ''}`}
        >
          {/* Top Section */}
          <div>
            {/* Logo */}
            <Menu.Item as={Link} to="/" onClick={handleButtonClick} className="navbar-sidebar-logo">
              <img src={Logo} alt="Logo" />
            </Menu.Item>

            {/* Sidebar Items */}
            <Menu.Item as={Link} to="/Speedruns" onClick={handleButtonClick}>
              <Icon name="chart line" />
              Speedruns
            </Menu.Item>
            <Menu.Item as={Link} to="/CGB" onClick={handleButtonClick}>
              <Icon name="gamepad" />
              CGB
            </Menu.Item>
            <Menu.Item as={Link} to="/DiscordServers" onClick={handleButtonClick}>
              <Icon name="discord" />
              Discord Servers
            </Menu.Item>
            <Menu.Item as={Link} to="/Tickets" onClick={handleButtonClick}>
              <Icon name="ticket" />
              Tickets
            </Menu.Item>
          </div>

          {/* Conditional Rendering for Profile/Login Buttons */}
          <div className="navbar-sidebar-profile-icon">
            {loading ? (
              <Menu.Item className="navbar-loading-text">
                <Icon name="spinner" loading />
                Loading...
              </Menu.Item>
            ) : isLoggedIn ? (
              // Profile Dropdown with Profile Picture and Username
              <Dropdown
                item
                className="navbar-profile-dropdown"
                pointing="top left"
                icon={null} // Disable the default dropdown arrow
                trigger={
                  <span>
                    {profilePicUrl ? (
                      <Image src={profilePicUrl} avatar />
                    ) : (
                      <Icon name="user circle" size="large" />
                    )}
                    {username || 'User'}
                  </span>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/Profile" onClick={handleButtonClick}>
                    <Icon name="user" />
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>
                    <Icon name="sign out" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              // Login/Register Button
              <Menu.Item as={Link} to="/Register" onClick={handleButtonClick} className="navbar-sidebar-login-button">
                <Icon name="user plus" />
                Register/Login
              </Menu.Item>
            )}
          </div>
        </Sidebar>

        {/* Main Content */}
        <div className={`navbar-main-content ${!visible ? 'navbar-main-content-collapsed' : ''}`}>
          {/* Toggle Button */}
          <div
            className="navbar-toggle-button"
            style={{ left: visible ? '205px' : '2px' }} // Dynamically set the left position
          >
            <button onClick={handleToggleNavbar}>{visible ? '❮' : '❯'}</button>
          </div>

          <Segment basic>
            <Routes>
              <Route path="/Speedruns" element={<Speedruns />} />
              <Route path="/CGB" element={<CGB />} />
              <Route path="/DiscordServers" element={<DiscordServers />} />
              <Route path="/Register" element={<Register />} />
              <Route path="/Profile" element={<UserProfile />} />
              <Route path="/user/:username" element={<UserProfileView />} />
              <Route path="/Tickets" element={<Tickets />} />
              <Route path="/" element={<Home />} />
            </Routes>
            {/* Render children */}
            {children}
          </Segment>
        </div>
      </div>
    </Router>
  );
}
