import React, { useState, useEffect } from 'react';
import './Home.css';

const useMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

function Home() {
    const isMobile = useMobile();
    const [updates, setUpdates] = useState([]);
    const [homeMainContent, setHomeMainContent] = useState({ title: '', description: '' });

    useEffect(() => {
        if (isMobile) {
            import('./Home_Mobile.css');
        }
    }, [isMobile]);

    useEffect(() => {
        const fetchUpdates = async () => {
            try {
                const response = await fetch('https://servers.jumpnexus.org/updates');
                const data = await response.json();
                setUpdates(data);
            } catch (error) {
                console.error('Error fetching updates:', error);
            }
        };

        fetchUpdates();
    }, []);

    useEffect(() => {
        const fetchHomeMainContent = async () => {
            try {
                const response = await fetch('https://servers.jumpnexus.org/homemain');
                const data = await response.json();
                setHomeMainContent(data);
            } catch (error) {
                console.error('Error fetching home main content:', error);
            }
        };

        fetchHomeMainContent();
    }, []);

    return (
        <div className="home-main-container">
            <div className="home-content-block first-block">
                <h2 className="home-titles">{homeMainContent.title}</h2>
                <p className="home-main-text">{homeMainContent.description}</p>
            </div>
            <div className="home-content-block news-block">
                <h2 className="home-titles">News and Updates</h2>
                {updates.length > 0 ? (
                    <ul className="updates-list">
                        {updates.map((update, index) => (
                            <li key={index} className="update-item">
                                <h3 className="update-title">{update.title}</h3>
                                <p className="update-date">{new Date(update.date).toLocaleDateString()}</p>
                                <div className="update-description">
                                    {update.description
                                        .split('~') // Split the description by '~'
                                        .filter(item => item.trim() !== '') // Remove empty strings
                                        .map((item, idx) => (
                                            <p key={idx}>{item.trim()}</p> // Render each item in a <p>
                                        ))}
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="home-main-text">No updates available at the moment.</p>
                )}
            </div>
            <div className="home-content-block third-block">
                <h2 className="home-titles">Contact us!</h2>
                <p className="home-main-text">
                    If you have ideas for the website or found an issue you can submit a ticket in the sidebar and it will go directly to the developers!
                </p>
            </div>
        </div>
    );
}

export default Home;
