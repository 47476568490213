import React, { useState, useEffect, useRef } from "react";
import { auth } from "../firebaseConfig";
import axios from "axios";
import { Link } from "react-router-dom"; // For user profile navigation
import "./ChatOverlay.css";
const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 600);
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};
const ChatOverlay = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [username, setUsername] = useState("Guest");
  const chatWindowRef = useRef(null);
  const ws = useRef(null);

  // Function to fetch username from Firestore
  const fetchUsername = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        setUsername("Guest");
        return;
      }

      const token = await user.getIdToken();

      // Fetch user data from Firestore or backend
      const response = await axios.get(`/userdata/${user.uid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.username) {
        setUsername(response.data.username);
      } else {
        console.error("Username not found in Firestore.");
        setUsername("Anonymous");
      }
    } catch (error) {
      console.error("Error fetching username:", error);
      setUsername("Anonymous");
    }
  };
    const isMobile = useMobile();

    useEffect(() => {
        if (isMobile) {
            import('./ChatOverlay_Mobile.css');
        }
    }, [isMobile]);
  // Fetch username when the component mounts
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUsername();
      } else {
        setUsername("Guest");
      }
    });
  }, []);

  // Establish WebSocket connection
  useEffect(() => {
    ws.current = new WebSocket("wss://servers.jumpnexus.org");

    ws.current.onopen = () => {
      console.log("WebSocket connection opened.");
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      setMessages((prev) => {
        // Avoid adding duplicate messages
        if (prev.find((msg) => msg.timestamp === message.timestamp && msg.uid === message.uid)) {
          return prev;
        }
        return [...prev, message];
      });
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      ws.current.close();
    };
  }, []);

  // Fetch all messages from Google Sheets or backend
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get("/get-messages-google");
        setMessages(response.data.messages || []);
      } catch (error) {
        console.error("Error fetching messages from Google Sheets:", error);
      }
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatWindowRef.current && !chatWindowRef.current.contains(event.target)) {
        setIsOpen(false); // Close the chat window
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Send a new message to Google Sheets and WebSocket
  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      const user = auth.currentUser;
      if (!user) {
        alert("You need to log in to send a message.");
        return;
      }

      const token = await user.getIdToken();

      const messageData = {
        uid: user.uid, // UID for linking profiles
        username, // Use the fetched username
        message: newMessage.trim(),
        timestamp: new Date().toISOString(),
      };

      // Send message to Google Sheets
      await axios.post("/save-message-google", messageData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Send message through WebSocket
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify(messageData));
      }

      // Update local message list
      setMessages((prev) => [...prev, messageData]);
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Toggle chat window visibility
  const toggleChat = () => setIsOpen(!isOpen);

  return (
    <div className="chat-overlay-container">
      <button className="chat-toggle-button" onClick={toggleChat}>
        {isOpen ? "✖" : "💬"}
      </button>
      {isOpen && (
        <div className="chat-window" ref={chatWindowRef}>
          <div className="chat-header">
  <h4>Chat</h4>
  {isMobile && (
    <button
      className="close-chat-button"
      onClick={() => setIsOpen(false)}
    >
      ✖
    </button>
  )}
</div>
          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat-message ${
                  auth.currentUser && msg.uid === auth.currentUser.uid ? "self-message" : ""
                }`}
              >
                <div className="chat-username">
                  <Link to={`/user/${msg.username}`} className="chat-username-link">
                    {msg.username}
                  </Link>
                </div>
                <div className="chat-text">{msg.message}</div>
                <div className="chat-timestamp">
                  {new Date(msg.timestamp).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className="chat-input-container">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendMessage();
                }
              }}
              placeholder="Type your message..."
              className="chat-input"
            />
            <button className="send-button" onClick={sendMessage}>
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatOverlay;