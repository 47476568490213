import React, { useState, useEffect } from 'react';
import './Tickets.css';
import './Tickets_Mobile.css'
import { auth } from '../../firebaseConfig'; // Import Firebase auth
import axios from 'axios'; // Import Axios for API calls

export default function Tickets() {
  const [formData, setFormData] = useState({
    username: 'Non-user', // Default username for non-logged-in users
    issue: '',
    description: '',
    discordUsername: '', // New field for Discord username
  });

  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const response = await axios.get(`/userdata/${user.uid}`, {
            headers: {
              Authorization: `Bearer ${await user.getIdToken()}`,
            },
          });
          const { username } = response.data;
          setFormData((prevFormData) => ({
            ...prevFormData,
            username,
          }));
        } catch (error) {
          console.error('Error fetching user data:', error);
          setFormData((prevFormData) => ({
            ...prevFormData,
            username: 'Non-user',
          }));
        }
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const webhookUrl = 'https://discord.com/api/webhooks/1315059637388578896/bsqEBuyhxCrj9L9Dwk-RP9OexmzvqvjzhOOTl62htjlguYpS2v3fNAhix-uNB_bO2MYC';

    const payload = {
      embeds: [
        {
          title: 'New Support Ticket',
          fields: [
            { name: 'Username', value: formData.username || 'N/A', inline: true },
            { name: 'Discord Username', value: formData.discordUsername || 'N/A', inline: true },
            { name: 'Issue', value: formData.issue || 'N/A', inline: true },
            { name: 'Description', value: formData.description || 'N/A' },
          ],
          color: 5814783,
        },
      ],
    };

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setMessage('Your ticket has been submitted successfully!');
        setFormData({ username: formData.username, issue: '', description: '', discordUsername: '' });
      } else {
        setMessage('Failed to submit the ticket. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting ticket:', error);
      setMessage('An error occurred while submitting your ticket.');
    }
  };

  return (
    <div className="ticket-form-container">
      <h2>Submit a Ticket</h2>
      <form className="ticket-form-box" onSubmit={handleSubmit}>
        <input
          type="text"
          name="username"
          value={formData.username}
          placeholder="Username"
          readOnly
        />
        <input
          type="text"
          name="discordUsername"
          value={formData.discordUsername}
          placeholder="Discord Username (optional)"
          onChange={handleChange}
        />
        <select
          name="issue"
          value={formData.issue}
          onChange={handleChange}
          required
        >
          <option value="" disabled>
            Select an Issue
          </option>
          <option value="Bug Report">Bug Report</option>
          <option value="Feature Request">Feature Request</option>
          <option value="General Inquiry">General Inquiry</option>
        </select>
        <textarea
          name="description"
          value={formData.description}
          placeholder="Describe your issue..."
          onChange={handleChange}
          required
        ></textarea>
        <div className="ticket-button-group">
          <button className="ticket-button" type="submit">
            Submit
          </button>
        </div>
        <p className="ticket-info-message">
          If you need to be contacted about your ticket, include your Discord username; otherwise, we will not be able to reach out to you.
        </p>
      </form>
      {message && <p className={message.includes('successfully') ? 'ticket-success-message' : 'ticket-error-message'}>{message}</p>}
    </div>
  );
}
