// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyD1vSyiSG2zlzU9nfwqEWHadAWx72B4s6I",
    authDomain: "jumpnexus-cc752.firebaseapp.com",
    projectId: "jumpnexus-cc752",
    storageBucket: "jumpnexus-cc752.appspot.com",
    messagingSenderId: "487083933981",
    appId: "1:487083933981:web:e3d0bbbd556b30735661f4",
    measurementId: "G-VTF3KHTF55"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
